<template>
    <CRow>
        <CCol col="12" lg="12">
            <CCard>
                <CCardBody>
                    <form
                        @submit.prevent="createRecord('LOCATIONS', {name:name})"
                        method="post"
                    >
                        <h3 v-if="isEditing">
                            {{ $t('button.edit') }} {{ $t('locations.title') }}
                        </h3>
                        <h3 v-else>{{ $t('button.create') }} {{ $t('locations.title') }}</h3>
                        <br>
                        <CRow>
                            <CCol col="12" lg="12">
                                <CInput 
                                    :placeholder="$t('fields.name.placeholder')" 
                                    :label="$t('fields.name.label')" 
                                    v-model="name" 
                                    type="text" 
                                    required
                                ></CInput>
                            </CCol>
                       </CRow>
                        <CRow>
                            <CCol col="12" lg="12" class="text-right">
                                <CButton color="success" type="submit" class="m-2">
                                    <template v-if="isEditing">
                                        <CIcon :content="updateIcon" /> {{ $t('button.update') }}
                                    </template>
                                    <template v-else>
                                        <CIcon :content="addIcon" /> {{ $t('button.create') }}
                                    </template>
                                </CButton>
                                <CButton color="primary" to="/locations" class="m-2">
                                    <CIcon :content="backIcon" /> {{ $t('button.back') }}
                                </CButton>
                            </CCol>
                        </CRow>
                    </form>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios'
import catalog from '../../hooks/catalogs';
import { cilSync, cilPlus, cilArrowLeft } from '@coreui/icons';

export default {
    name: 'LocationCreate',
    mixins: [catalog],
    data() {
        return {
            updateIcon: cilSync,
            addIcon: cilPlus,
            backIcon: cilArrowLeft,
            name: null,
        }
    },
    mounted() {
        let self = this;

        if (this.isEditing) {
            axios.get(`api/locations/${this.$route.params.id}`)
                .then(response => {
                    self.name = response.data.data.name;
                }).catch(error => {
                    if (error.response.status === 401) {
                        self.$router.push({ path: '/login' });
                    } else {
                        this.$toast.error(error.response.data.message);
                    }
                });
        }     
    }
}
</script>
